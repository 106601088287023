import React from "react";
import englishTranslations from "../../../locales/en/translation.json";
import {
  Trans,
  useTranslation,
  useI18next,
  i18next,
} from "gatsby-plugin-react-i18next";

// <Trans i18nKey={"GREETING"}>{englishTranslations.GREETING}</Trans>
//  <Trans i18nKey={"n/a"}>Component hardcoded</Trans>
// {t("GREETING", englishTranslations?.GREETING)}
//  {t(languageData?.translation?.GREETING)}

const Translate = ({ children, i18nKey }) => {
  // console.log("i18nKey", i18nKey);
  // const translationKey = {};
  if (i18nKey) {
    // translationKey.i18nKey = i18nKey;
    // const englishTranslation = englishTranslations[i18nKey] ? englishTranslations[i18nKey] : children
    return (
      <Trans i18nKey={i18nKey}>
        {englishTranslations[i18nKey] || children}
      </Trans>
    );
  }
  //   if (children) {

  return <Trans>{children}</Trans>;
  //   }
  //   return fallback ? t(value, fallback) : t(value);
};

export default Translate;
export const t = (keyValue, fallback, useText) => {
  const { t: translateFuncton, language } = useI18next();
  // console.log("fallback", fallback);

  return translateFuncton(keyValue, englishTranslations[keyValue] || fallback);
  // return fallback
  //   ? translateFuncton(keyValue, fallback)
  //   : translateFuncton(
  //       !useText && englishTranslations[keyValue]
  //         ? englishTranslations[keyValue]
  //         : keyValue
  //     );
};
